import styled from "styled-components";
import {
  getBorderRadius,
  getFadeInAnimation,
  getSize,
  responsive,
} from "~/spocketUI/helpers/style";
import { UIProps } from "~/spocketUI/types/style";

interface IModal extends UIProps {
  padding?: string;
  minWidth: string | number;
}

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  // Fade in the modal once added to the DOM
  ${getFadeInAnimation(0.3)}
`;

export const ModalContent = styled.div<IModal>`
  position: fixed;
  background: white;
  min-width: ${({ minWidth }) => minWidth};
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${getBorderRadius(1.2)};
  max-height: 80%;
  overflow: auto;
  padding: ${({ padding }) => padding ? padding : getSize(3.2)};
  ${responsive("tablet")} {
    padding: ${({ padding }) => padding ? padding : getSize(5.8)};
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  padding: ${getSize(1.2)};
  ${responsive("tablet")} {
    padding: ${getSize(2.4)};
  }
`;
