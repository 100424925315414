import React from "react";
import * as S from "./styles";

export type TextProps = {
  secondary?: boolean;
  children: React.ReactNode;
};

const Text = ({ children, ...rest }: TextProps) => {
  return <S.Text {...rest}>{children}</S.Text>;
};

export default Text;
