import { ILimit, Limits } from "~/types/billing";
import { useAccount } from "./useAccount";
import { useDebounceEffect } from "./useDebounceEffect";

export const usePlanFeature = () => {
  const { account, refetch, getAccount} = useAccount();

  useDebounceEffect(
    () => {
      /* Fix the current plan bug when a user subscribes, and the account is not updated with the required information
    about then plan.
    */
      refetch();
    },
    [],
    100
  );

  const isFeatureEnabled = async (limitType: Limits) => {
    const account = await getAccount()
    
    const limits: ILimit | Record<any, any> =
      account?.active_subscription?.plan?.limits || {};

    if (!account?.active_subscription) return false;
    if (account?.active_subscription?.paused_at) return false;

    if (limitType == Limits.PAID_PLAN) {
      return !!account?.active_subscription?.plan;
    }

    if (limitType == Limits.MAX_BRAND) {
      return (
        (account?.company_count as number) < (limits?.max_brand_kits as number)
      );
    }

    if (limitType == Limits.MAX_LOGOS) {
      return (account?.company_count as number) < (limits?.max_logos as number);
    }

    if (!limits) return false;
    // @ts-ignore
    return !!limits[limitType];
  };

  const isFeatureDisabled = async (value: Limits) => {
    const enabled = await isFeatureEnabled(value);
    return !enabled;
  };

  const isFeaturePaused = () => !!account?.active_subscription?.paused_at;

  return { isFeatureEnabled, isFeatureDisabled, isFeaturePaused };
};
