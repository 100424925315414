import { lazy } from "react";
import { app, guest } from "~/helpers/routes";
import { paths } from "~/router/paths";

const SavedLogosPage = lazy(() => import("~/pages/saved-logos"));
const SavedBrandKitsPage = lazy(() => import("~/pages/saved-brandkits"));
const EnterLogoName = lazy(() => import("~/pages/quiz/EnterLogoNamePage"));
const PickColorPage = lazy(() => import("~/pages/quiz/PickColorPage"));
const PickIndustryPage = lazy(() => import("~/pages/quiz/IndustryPage"));
const PickLogoPage = lazy(() => import("~/pages/quiz/PickLogoPage"));
const LogoCustomizationPage = lazy(() => import("~/pages/customization"));
const CheckoutGuestPage = lazy(() => import("~/pages/checkout"));
const PickStylePage = lazy(() => import("~/pages/quiz/PickStylePage"));
const SharePage = lazy(() => import("~/pages/share"));

export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(SavedLogosPage),
  },
  {
    path: paths.app.brand_kit,
    element: app(SavedBrandKitsPage),
  },
  {
    path: paths.app.companyName,
    element: guest(EnterLogoName),
  },
  {
    path: paths.app.color,
    element: guest(PickColorPage),
  },
  {
    path: paths.app.industry,
    element: guest(PickIndustryPage),
  },
  {
    path: paths.app.style,
    element: guest(PickStylePage),
  },
  {
    path: paths.app.logo,
    element: guest(PickLogoPage),
  },
  {
    path: paths.app.logo_customization,
    element: guest(LogoCustomizationPage),
  },
  {
    path: paths.app.checkout,
    element: guest(CheckoutGuestPage),
  },
  {
    path: paths.app.share,
    element: guest(SharePage),
  },
];
