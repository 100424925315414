
import { useCallback, useEffect, useMemo, useState } from "react";
import { IPlan, SubscriptionType } from "~/types/billing";
import { usePlans } from "~/api/billing/queries";
import { useDebounceEffect } from "~/hooks/useDebounceEffect";
import { useAccount } from "~/hooks/useAccount";

interface IUsePlansList {
  initialIsAnnual: boolean
}

export const usePlansList = ({ initialIsAnnual }: IUsePlansList) => {
  const { data: plans, isLoading } = usePlans();
  const { account, refetch } = useAccount();
  const [isAnnual, setIsAnnual] = useState<boolean>(initialIsAnnual);

  const isCurrentPlanAnnual =
    account?.active_subscription?.plan?.interval === SubscriptionType.ANNUAL;
  
  const filteredPlans = useMemo(() => {
    if (!plans) return [];
    let filteredPlans: IPlan[] = [];

    if (isAnnual) {
      filteredPlans = plans?.filter((plan) => plan.interval === SubscriptionType.ANNUAL);
    } else {
      filteredPlans = plans?.filter((plan) => plan.interval === SubscriptionType.MONTHLY);
    }

    return filteredPlans.map((plan, index) => {
      plan.previousPlanName = index > 0 ? filteredPlans[index - 1].name : null;

      if (isAnnual && index === 0) {
        const monthlyPlans = plans?.filter((plan) => plan.interval === SubscriptionType.MONTHLY)
        plan.previousPlanName = monthlyPlans[0].name || null;
      }

      return plan;
    });
  }, [plans, isAnnual]);

  const biggestMonthsOff = useMemo(() => {
    return plans?.reduce((maxMonths, plan) => {
      return Math.max(maxMonths, plan.months_off);
    }, 0);
  }, [plans]);

  const savedMoneyInAnnual = useMemo(() => {
    if (!plans) return 0;

    const sortedPlans = plans
      .filter((plan) => plan.interval === SubscriptionType.ANNUAL)
      .sort((a, b) => b.cost_per_month - a.cost_per_month);

    const { cost_per_month, old_cost_per_month  } = sortedPlans[0];
    if (!old_cost_per_month) return 0;
    const savedMoney = ((old_cost_per_month - cost_per_month) * 12) / 100;
    
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(savedMoney);
  }, [plans]);

  const toggleControl = useCallback(
    () => setIsAnnual(!isAnnual),
    // () => (isCurrentPlanAnnual ? setIsAnnual(true) : setIsAnnual(!isAnnual)), // TODO: Uncomment after shopify app review
    [isCurrentPlanAnnual, isAnnual]
  );

  useEffect(() => {
    if (isCurrentPlanAnnual) setIsAnnual(true);
  }, [isCurrentPlanAnnual]);

  useDebounceEffect(() => {
      refetch();
    }, [], 100
  );

  const highestPricePlan = useMemo(
    () =>
      filteredPlans?.reduce(
        (prev, current) =>
          current.cost_per_month > prev.cost_per_month ? current : prev,
        filteredPlans[0]
      ),
    [filteredPlans]
  );

  return {
    account,
    isLoading,
    isAnnual,
    biggestMonthsOff,
    savedMoneyInAnnual,
    filteredPlans,
    highestPricePlan,
    toggleControl,
  }
}