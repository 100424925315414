export const currentEnv: string = import.meta.env.VITE_ENV;
export const buildNumber: number = import.meta.env.VITE_BUILD_NUMBER;
export const sentryDSN: string = import.meta.env.VITE_SENTRY_DSN;
export const googleGAKey: string = import.meta.env.VITE_GOOGLE_GA_KEY;
export const intercomKey: string = import.meta.env.VITE_INTERCOM_KEY;
export const sentryProject: string = import.meta.env.VITE_SENTRY_PROJECT;
export const heapProjectId: string = import.meta.env.VITE_HEAP_PROJECT_ID;

export const isDevelopment: boolean = currentEnv === "development";
export const isStaging: boolean = currentEnv === "staging";
export const isProduction: boolean = currentEnv === "production";
