import { Trans, useTranslation } from "react-i18next";
import * as S from "./plans.style";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import { usePlansList } from "~/hooks/usePlansList";
import Toggle from "~/spocketUI/components/Toggle";
import { PlanCard } from "./planCard";

interface IPlansProps {
  closeModal: () => void;
  initialIsAnnual?: boolean;
}

export const Plans = ({ closeModal, initialIsAnnual = false }: IPlansProps) => {
  const { t } = useTranslation();
  const {
    isLoading,
    savedMoneyInAnnual,
    isAnnual,
    filteredPlans,
    toggleControl,
    account
  } = usePlansList({ initialIsAnnual });
  
  return (
    <S.Plans>
      <S.Header>
        <S.Title>{t("plans_modal.title")}</S.Title>
        <FlexContainer
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          data-testid="plan-cycle">
          <S.ToggleContentText className={`${!isAnnual ? "selected" : ""}`}>
            {t("settings.monthly")}
          </S.ToggleContentText>
          <Toggle onChange={toggleControl} value={isAnnual} />
          <S.ToggleContentText className={`${isAnnual ? "selected" : ""}`}>
            {t("settings.annual")}
          </S.ToggleContentText>
          {savedMoneyInAnnual?
            <S.PromotionText>
              {<Trans
                i18nKey="plans_modal.save"
                values={{ value: savedMoneyInAnnual }}
              />}
            </S.PromotionText> : null}
        </FlexContainer>
      </S.Header>

      <S.PlansList>
        {filteredPlans?.map((plan) => {
            return (
              <PlanCard
                account={account}
                key={plan.id}
                previousPlanName={plan.previousPlanName as string | null}
                plan={plan}
                closeModal={closeModal}
              />
            );
          })}
      </S.PlansList>
    </S.Plans>
  )
}