import { triggerShowResumeModal } from "~/spocketUI/helpers/customEvents";
import { useAccount } from "./useAccount";
import { IPlan } from "~/types/billing";
import { paths } from "~/router/paths";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";

export const usePlansRedirect = (plan?: IPlan) => {
  const navigate = useNavigate();
  const params = useParams();
  const { account, getAccount } = useAccount();
  const isSubscribed = plan?.id === account?.active_subscription?.plan?.id;

  const buttonText = useMemo(() => {
    if (isSubscribed && account?.active_subscription?.paused_at)
      return "paused"

    if (isSubscribed)
      return "current"

    return "start"
  }, [plan, isSubscribed, account]);

  const redirectToCheckout = async () => {
    const account = await getAccount();
    if (account?.active_subscription?.paused_at) return triggerShowResumeModal();

    localStorage.setItem("plansModal", "false");

    if (!account) {
      const planId = plan?.id?.toString();
      const href = window.location.href;
      const companyId = href.includes("company_id=") ? href.split("company_id=").pop() : params.company_id;

      const url = paths.app.checkout.replace(
        ":company_id",
        companyId as string
      );
      return navigate(url + (planId ? `?plan_id=${planId}` : ""));
    }

    const includesCompanyId =window.location.href.includes("?company_id=")
    const companyId = includesCompanyId ? window.location.href.split("?company_id=").pop() : null;

    if (companyId) navigate(`/checkout/${plan?.id}?company_id=${companyId}`);
    else navigate(`/checkout/${plan?.id}`);
  };

  return { redirectToCheckout, buttonText, isSubscribed };
}