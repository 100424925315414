import styled, { css } from "styled-components";
import {
  getColor,
  getColorWithAlpha,
  getSize
} from "~/spocketUI/helpers/style";
import { UIProps } from "~/spocketUI/types/style";

interface IIsHighlighted extends UIProps {
  isHighlighted?: boolean;
}

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSize(4.2)} ${getSize(3.6)};
  flex: 1;
  color: #000;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: ${getSize(2.2)};
`;

export const Title = styled.h2`
  font-size: ${getSize(2.0)};
  font-weight: 700;
  margin: 0;
`;

export const ToggleContentText = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.84px;
  color: ${getColor("textDisabled")};

  &.selected {
    font-weight: 500;
    color: ${getColor("text")};
  }
`;

export const PromotionText = styled.div`
  position: relative;
  background-color: ${getColor("greenSecondary")};
  color: ${getColor("green")};
  margin: 0;
  padding: 2px;
  padding: 2px ${getSize(1)};
  border-radius: ${getSize(3)};
  font-size: ${getSize(1.2)};
  font-weight: 500;
  line-height: ${getSize(1.8)};
`;

export const PlansList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSize(1.6)};
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${getSize(1.2)};
    height: ${getSize(1.2)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${getColor("border")};
    border-radius: ${getSize(1.0)};
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: ${getSize(1.0)};
  }
`;

export const PlanCard = styled.div<IIsHighlighted>`
  display: flex;
  flex-direction: row;
  background-color: #FBFBFB;
  border: 1px solid ${getColor("border")};
  padding: ${getSize(1.8)} ${getSize(2.8)};
  border-radius: ${getSize(1.4)};
  
  ${({ isHighlighted }) => isHighlighted && css`
    border: 2px solid ${getColor("primary")};
    background: linear-gradient(180deg, #E2E5FF 0%, #FFFFFF 100%);
  `}

  @media (max-width: 660px) {
    flex-direction: column;
    padding: ${getSize(1.8)};
  }
`;

export const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${getSize(1.4)};
  padding-right: ${getSize(2.8)};

  @media (max-width: 660px) {
    padding-right: 0;
    align-items: center;
    padding-bottom: ${getSize(1.8)};
  }
`;

export const PlanFeatures = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSize(1.4)};
`;

export const PlanName = styled.h3`
  font-size: ${getSize(1.6)};
  font-weight: 600;
  margin: 0;
`;

export const PlanCardPrice = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Monthly = styled.h3`
  font-size: ${getSize(2.6)};
  font-weight: 700;
  line-height: ${getSize(3.2)};
  letter-spacing: 0.0025em;
  position: relative;
  display: flex;
  margin: 0;
  align-items: center;
`;

export const PlanBilledAnnualText = styled.h3`
  color: ${getColor("primary")};
  font-size: ${getSize(1.3)};
  font-weight: 500;
  letter-spacing: 0.0025em;
  padding: 0;
  margin: 0;
`;

export const DiscountText = styled.span`
  width: max-content;
  color: #606888;
  font-size: ${getSize(2.0)};
  font-weight: 500;
  line-height: ${getSize(2.4)};
  letter-spacing: 0.0025em;
  text-decoration: line-through;
  margin-right: ${getSize(0.6)};
`;

export const MonthlyValue = styled.span`
  font-size: ${getSize(1.4)};
  line-height: ${getSize(1.6)};
  font-weight: 600;
  margin-left: ${getSize(0.5)};
`;

export const DecimalValue = styled.span`
  font-size: ${getSize(1.5)};
  font-weight: 600;
`;

export const Button = styled.button<IIsHighlighted>`
  border: none;
  border-radius: ${getSize(0.6)};
  font-size: ${getSize(1.4)};
  font-weight: 600;
  padding: ${getSize(0.8)} ${getSize(2.4)};
  width: 100%;
  max-width: ${getSize(16.5)};
  background-color: ${({ isHighlighted }) => isHighlighted ? getColor("primary") : getColorWithAlpha("primary", 0.1)};
  color: ${({ isHighlighted }) => isHighlighted ? getColor("white") : getColor("primary")};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  opacity: 0.2;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(102, 102, 102, 1) 40%,
    rgba(37, 37, 37, 0) 100%
  );
`;

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSize(0.2)};
  padding-left: ${getSize(2.8)};
  flex: 1;
  justify-content: center;

  @media (max-width: 660px) {
    padding-left: 0;
  }
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${getSize(1.6)};
`;

export const FeatureText = styled.div`
  font-weight: 600;
  color: ${getColor("black")};
  font-size: ${getSize(1.3)};
  width: calc(100% - ${getSize(3.6)});
`;

export const EverythingPlusText = styled.div`
  font-size: ${getSize(1.3)};
  font-weight: 600;
  margin-bottom: ${getSize(0.8)};
`;