import { Icon } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faLifeRing,
  faFolderArrowDown,
  faWandMagicSparkles,
} from "@fortawesome/pro-light-svg-icons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";

export const navItems: Array<INavItem> = [
  {
    namePath: "nav.saved_logos",
    path: paths.app.home,
    icon: faFolderArrowDown as Icon,
  },
  {
    namePath: "nav.make_logo",
    path: paths.app.begin,
    icon: faWandMagicSparkles as Icon,
  },
  {
    namePath: "nav.generate_brand_kit",
    path: paths.app.brand_kit,
    icon: faWandMagicSparkles as Icon,
  },
  {
    namePath: "settings.title",
    path: paths.settings.plans,
    icon: faCog as Icon,
  },
  {
    namePath: "nav.help_center",
    path: "https://help.logome.ai/Logome-ai-7eabf503440f45329e5a363f3a7034d2",
    openInNewTab: true,
    icon: faLifeRing as Icon,
    // Uncomment this to show help center as a modal
    // showHelpCenterModal: true,
  },
];
