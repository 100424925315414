import { IPlan, SubscriptionType } from "~/types/billing";
import * as S from "./plans.style";
import { IAccount } from "~/types/account";
import { Trans, useTranslation } from "react-i18next";
import { centsToDecimal } from "~/spocketUI/helpers/numbers";
import { SVG } from "~/spocketUI/components/SVG";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { SVGIcon } from "~/spocketUI/components/SVG/types";
import { getFeatures, hasEverythingPlus, showOldPrice } from "./config";
import { useMemo } from "react";
import { usePlansRedirect } from "~/hooks/usePlanRedirect";


type Props = {
  plan?: IPlan;
  previousPlanName: string | null;
  account: IAccount | null;
  closeModal?: () => void;
};

export const PlanCard = ({ plan, account, previousPlanName, closeModal }: Props) => {
  const { t } = useTranslation();
  const { redirectToCheckout, buttonText, isSubscribed } = usePlansRedirect(plan);
  
  const { isAnnual, annualCost } = useMemo(() => {
    const isAnnual = plan?.interval === SubscriptionType.ANNUAL;
    const annualCost = isAnnual ?  (plan?.cost_per_month * 12) / 100 : 0;

    return { isAnnual, annualCost: annualCost.toFixed(0) };
  }, [plan]);

  const handleRedirect = () => {
    redirectToCheckout();
    if (closeModal) closeModal();
  };

  return (
    <S.PlanCard isHighlighted={plan?.is_highlighted}>
      <S.PlanDetails>
        <S.PlanName>{plan?.name}</S.PlanName>

        <S.PlanCardPrice>
          <S.Monthly>
            {plan?.old_cost_per_month && showOldPrice(plan) ? (
              <S.DiscountText color={plan?.is_highlighted ? "white" : "primary"}>
                ${centsToDecimal(plan?.old_cost_per_month)}
              </S.DiscountText>
            ) : null}
            <Trans
              i18nKey="plans_modal.mon"
              components={{
                1: <S.MonthlyValue />
              }}
              values={{
                integer: centsToDecimal(plan?.cost_per_month as number).toFixed(0)
              }}
            />
          </S.Monthly>

          {isAnnual && (
            <S.PlanBilledAnnualText>
              <Trans
                i18nKey={"settings.billed_annually"}
                values={{
                  amount: `$${annualCost}`,
                }}
              />
            </S.PlanBilledAnnualText>
          )}
        </S.PlanCardPrice>

        <S.Button
          disabled={isSubscribed}
          onClick={handleRedirect}
          isHighlighted={plan?.is_highlighted}>
          {t(`plans_modal.${buttonText}`)}
        </S.Button>
      </S.PlanDetails>
      <S.Divider />

      <S.Features data-testid="plan-features">
        {plan && hasEverythingPlus(plan) ?
          <S.EverythingPlusText>
            {t("plans_modal.everything_plus")}
          </S.EverythingPlusText>
        : null}

        {plan && getFeatures(plan).map((feature: string, i: number) => (
          <S.Feature key={`${feature}-${i}`}>
            <SVG
              icon={faCheckCircle as SVGIcon}
              color={plan?.is_highlighted ? "primary" : "text"}
              size="1x"
            />
            <S.FeatureText>
              {t(`plans_modal.features.${feature}`)}
            </S.FeatureText>
          </S.Feature>
        ))}
      </S.Features>
    </S.PlanCard>
  );
}