import styled from "styled-components";
import { getColor } from "~/spocketUI/helpers/style";
import { TextProps } from ".";

export const Text = styled.span<TextProps>`
  font-size: 14px;
  line-height: 141%;
  font-weight: 400;
  color: ${(props) =>
    getColor(props.secondary ? "textSecondary" : "text")(props)};
`;
