import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
	NOTIFICATIONS
} from "./types";
import {
	getNotifications,
  postMarkAllRead,
  postMarkRead
} from "./requests";
import { INotification } from "~/types/notifications";

export const useGetNotifications = () =>
	useQuery<INotification[]>(NOTIFICATIONS, () => getNotifications());

export const useMarkAllNotificationsRead = () => useMutation(postMarkAllRead);

export const useMarkNotificationRead = () => useMutation(postMarkRead);
