import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { resumeSubscription } from "~/api/billing/requests";
import { useTranslation } from "react-i18next";
import handleErrors from "~/spocketUI/helpers/handleErrors";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import { SVG } from "~/spocketUI/components/SVG";
import Button from "~/spocketUI/components/Button";
import Label from "~/spocketUI/components/Label";
import { CenteredText } from "~/spocketUI/components/DialogModal/styles";
import Modal from "~/spocketUI/components/Modal";
import { useEffect, useState } from "react";
import { SHOW_RESUME_MODAL } from "~/spocketUI/helpers/customEvents";
import { useAccount } from "~/hooks/useAccount";

export const ResumeModal = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const { getAccount } = useAccount();

  useEffect(() => {
    const handleResumeModal = () => setShow(true);
    // @ts-ignore
    window.addEventListener(SHOW_RESUME_MODAL, handleResumeModal);
    return () => {
      // @ts-ignore
      window.removeEventListener(SHOW_RESUME_MODAL, handleResumeModal);
    };
  }, []);

  const handleResume = async () => {
    const toastMessages = {
      loading: t("settings.loading_unpause"),
      success: t("settings.unpause_success"),
      error: t("settings.unpause_error"),
    };

    const { errors } = await handleErrors(
      () => resumeSubscription(),
      toastMessages
    );

    if (!errors) {
      setTimeout(() => {
        getAccount();
        setShow(false);
      }, 200);
    }
  };

  return (
    <Modal
      isShowing={show}
      hide={() => setShow(false)}
      minWidth="20%"
      padding="32px">
      <FlexContainer flexDirection="column" gap={1.6}>
        <SVG icon={faArrowRotateRight} size="2xl" color="primary" />
        <FlexContainer width="260px" padding={0}>
          <Label text={t("settings.resume_modal_title")} alignItems="center">
            <CenteredText secondary>
              {t("settings.resume_modal_desc")}
            </CenteredText>
          </Label>
        </FlexContainer>
        <Button
          color="white"
          bgColor="primary"
          children={t("settings.unpause")}
          onClick={handleResume}
          size="lg"
          width="40%"
          alignSelf="center"
        />
        <Button
          color="secondary"
          bgColor="white"
          children={t("settings.go_back")}
          onClick={() => setShow(false)}
          width="40%"
          size="lg"
          alignSelf="center"
        />
      </FlexContainer>
    </Modal>
  );
};
