import { ReactNode, ReactPortal, useEffect } from "react";
import { ReactQueryProvider } from "./ReactQuery";
import { AccountProvider } from "./Account";
import { StoreProvider } from "./Store";
import { LogoInfoProvider } from "./LogoInfo";
import { heapProjectId } from "~/helpers/environment";

type Props = {
  children: ReactNode | ReactPortal;
};

export const ContextWrapper = ({ children }: Props) => {
  useEffect(() => {
    const psKey = import.meta.env.VITE_PARTNERSTACK_KEY;
    if (!psKey) {
      console.warn("Partnerstack key not found");
      return;
    }
    const script = document.createElement("script");
    script.src = "https://snippet.growsumo.com/growsumo.min.js";
    script.async = true;
    // @ts-ignore
    script.onload = script.onreadystatechange = function () {
      // @ts-ignore
      const state = this.readyState;
      if (!state || state === "complete" || state === "loaded") {
        try {
          // @ts-ignore
          window.growsumo._initialize(psKey);
          // @ts-ignore
          if (typeof window.growsumoInit === "function") {
            // @ts-ignore
            window.growsumoInit();
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const scriptContent = `
      window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){
        window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;
        var a=document.createElement("script");
        a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";
        var r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(a,r);
        var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity",
        "addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter",
        "addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties",
        "trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);
        window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};
        for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])
      };
      heap.load("${heapProjectId}");
    `;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = scriptContent;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <AccountProvider>
      <LogoInfoProvider>
        <StoreProvider>
          <ReactQueryProvider>{children}</ReactQueryProvider>
        </StoreProvider>
      </LogoInfoProvider>
    </AccountProvider>
  );
};
