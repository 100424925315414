import styled from "styled-components";

import {
  getBorderRadius,
  getColor,
  getFadeInAnimation,
  getSize,
} from "~/spocketUI/helpers/style";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  ${getFadeInAnimation(0.3)}
`;

export const ModalContent = styled.div`
  position: fixed;
  display: flex;
  overflow: hidden;
  background: white;
  height: auto;
  border-radius: ${getBorderRadius(1.2)};
  width: 100%;
  max-height: 95%;

  @media (min-width: 1441px) {
    max-width: 960px;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1440px) {
    width: auto;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    left: ${getSize(8.0)};
    right: ${getSize(8.0)};
  }

  @media (max-width: 460px) {
    width: auto;
    top: ${getSize(3.0)};
    bottom: ${getSize(3.0)};
    left: ${getSize(2.0)};
    right: ${getSize(2.0)};
    transform: none;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  position: absolute;
  top: ${getSize(0.8)};
  right: ${getSize(1.2)};
  cursor: pointer;
  padding: ${getSize(0.6)};

  svg {
    color: ${getColor("sidebarSectionTitle")};
    width: ${getSize(1.2)};
  }
`;

export const ModalSidebar = styled.div`
  width: ${getSize(37.4)};
  min-height: 200px;
  background-color: ${getColor("primary")};
  padding-top: ${getSize(6.4)};
  color: ${getColor("white")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    line-height: 36px;
    font-size: ${getSize(2.6)};
    margin-top: ${getSize(1.4)};
    font-weight: 400;
  }

  strong {
    font-weight: 600;
  }

  h4 {
    font-weight: 400;
    margin: 0;
    display: flex;
    align-items: center;
    gap: ${getSize(0.8)};
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const SidebarContent = styled.div`
  padding-left: ${getSize(2.4)};
`;

export const SidebarImage = styled.img`
  width: 100%;
  height: auto;
  padding-left: ${getSize(2.2)};
`;
