import { IPaths } from "~/types/routing";

export const paths: IPaths = {
  home: "/",
  companyName: "/company-name",
  industry: "/industry",
  color: "/color",
  logo: "/logo",
  style: "/begin",
  begin: "/begin",
  logo_customization: "/logo/customization/:id",
  brand_kit: "/brand-kit",
  checkout: "/checkout-guest/:company_id",
  share: "/s/:id",
};
