import { SVG } from "~/spocketUI/components/SVG";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { Plans } from "./plans";
import { SHOW_PLANS_MODAL, triggerShowResumeModal } from "~/spocketUI/helpers/customEvents";
import ReactDOM from "react-dom";
import { usePlanFeature } from "~/hooks/usePlanFeature";
import PlansmodalIllustration from "~/assets/svg/plansmodal_illustration.svg";
import LogoSvg from "~/assets/svg/mini_logome_logo.svg";
import * as S from "./styles";
import { Trans, useTranslation } from "react-i18next";

export const PlansModal = () => {
  const { t } = useTranslation();
  const [showPlansModal, setShowPlansModal] = useState<
    | { show: false; initialIsAnnual?: undefined }
    | { show: true; initialIsAnnual: boolean }
  >({
    show: false,
  });
  const { isFeaturePaused } = usePlanFeature();

  // Show plans modal when user needs to upgrade
  useEffect(() => {
    const handleRequiresUpgrade = (
      e: CustomEvent<{ initialIsAnnual: boolean }>
    ) => {
      if (isFeaturePaused()) {
        triggerShowResumeModal();
      } else {
        setShowPlansModal({
          show: true,
          initialIsAnnual: e.detail.initialIsAnnual,
        });
      }
    };
    // @ts-ignore
    window.addEventListener(SHOW_PLANS_MODAL, handleRequiresUpgrade);
    return () => {
      // @ts-ignore
      window.removeEventListener(SHOW_PLANS_MODAL, handleRequiresUpgrade);
    };
  }, [isFeaturePaused]);

  useEffect(() => {
    if (showPlansModal.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPlansModal]);

  if (!showPlansModal.show) return null;

  return ReactDOM.createPortal(
    <S.ModalWrapper>
      <S.ModalContent data-testid="plans-modal">
        <S.ModalSidebar>
          <S.SidebarContent>
            <h4><img src={LogoSvg}/> {t("plans_modal.sidebar.subtitle")}</h4>
            <h1>
              <Trans
                i18nKey="plans_modal.sidebar.title"
                components={{ 1: <strong />, 2: <br /> }}
              />
            </h1>
          </S.SidebarContent>
          <S.SidebarImage src={PlansmodalIllustration} alt="Plansmodal Illustration" />
        </S.ModalSidebar>
        <S.CloseContainer onClick={() => setShowPlansModal({ show: false })}>
          <SVG icon={faClose} size="xl" />
        </S.CloseContainer>
        <Plans
          closeModal={() => setShowPlansModal({ show: false })}
          initialIsAnnual={showPlansModal.initialIsAnnual}
        />
      </S.ModalContent>
    </S.ModalWrapper>,
    document.body
  );
};
