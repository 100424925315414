import React, { useEffect } from "react";
import * as S from "./PageWrapper.style";
import { UpgradeButton } from "./UpgradeButton";
import { Notifications } from "~/components/notifications";
import { useMediaQuery } from "~/spocketUI/hooks/useMediaQuery";
import { PlansModal } from "~/components/plansModal";
import { ResumeModal } from "~/components/resumeModal";
import { useNavigate } from "react-router-dom";
import { paths } from "~/router/paths";

interface IProps {
  children: React.ReactNode;
  isGuest?: boolean;
}

export const PageWrapper: React.FC<IProps> = ({ children = null, isGuest }) => {
  const isSettingsPage = location.pathname.includes("settings");
  const isNotMobile = useMediaQuery("tablet");
  const navigate = useNavigate();
  
  useEffect(() => {
    const isCheckout = location.pathname.includes("checkout");

    if (!isCheckout) {
      const companyId = sessionStorage.getItem("company_id");

      if (companyId) {
        sessionStorage.removeItem("company_id");
        navigate(`${paths.app.logo}?company_id=${companyId}`);
      }
    }
  }, []);

  if (isGuest) {
    return (
      <>
        <S.PageWrapperFullscreen>
          <>{children}</>
        </S.PageWrapperFullscreen>
        <PlansModal />
        <ResumeModal />
      </>
    );
  }

  return (
    <S.PageWrapper
      padding={isSettingsPage ? "24px 25px 58px" : "24px 92px 58px"}>
      <S.PageHeader>
        <UpgradeButton />
        {isNotMobile ? <Notifications /> : null}
      </S.PageHeader>
      <S.PageMaxWidth>
        <>{children}</>
      </S.PageMaxWidth>
      <PlansModal />
      <ResumeModal />
    </S.PageWrapper>
  );
};

export const PageWrapperFullScreen: React.FC<IProps> = ({
  children = null,
}) => {
  return (
    <S.PageWrapperFullscreen>
      <PlansModal />
      <ResumeModal />
      <>{children}</>
    </S.PageWrapperFullscreen>
  );
};
