import React, { useContext, useEffect, useState } from "react";

import { createContext } from "react";
import { paths } from "~/router/paths";
import { ILogoInfo } from "~/types/logo";

interface ILogoInfoContextProps {
  logoInfo: ILogoInfo | null;
  setLogoInfo: React.Dispatch<React.SetStateAction<ILogoInfo | null>>;
}

export const InfoContext = createContext<ILogoInfoContextProps>({
  logoInfo: null,
  setLogoInfo: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const LogoInfoProvider = ({ children }: Props) => {
  const [logoInfo, setLogoInfo] = useState<ILogoInfo | null>(null);

  useEffect(() => {
    if (!logoInfo) {
      const path = window.location.href;
      if (
        path.includes(paths.app.industry) ||
        path.includes(paths.app.companyName) ||
        path.includes(paths.app.color)
      )
        window.location.href = paths.app.begin;
    }
  }, [logoInfo]);

  return (
    <InfoContext.Provider value={{ logoInfo, setLogoInfo }}>
      {children}
    </InfoContext.Provider>
  );
};
