import { IPlan, SubscriptionType } from "~/types/billing";

export const showOldPrice = (plan: IPlan) => {
  return plan.name === "Basic Logo Pack" || plan.interval === SubscriptionType.ANNUAL;
}

export const getFeatures = (plan: IPlan) => {
  const features: { [key: string]: string[] } = {
    "Basic Logo Pack": [
      "unlimited_creations",
      "one_logo",
      "24_7_chat_support"
    ],
    "Premium Logo Pack": [
      "unlimited_creations",
      "five_logos",
      "high_res_files",
      "forever_customization",
      "vip_chat_support"
    ],
    "Logo + Brand Kit": [
      "10_logos",
      "10_brand_kits",
      "website"
    ]
  };

  return features[plan.name] || plan.features;
}

export const hasEverythingPlus = (plan: IPlan) => {
  return plan.name === "Logo + Brand Kit";
}
